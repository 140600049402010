<template>
  <div class=''>
	  <img :src="img" style="object-fit: cover;" class="w-max">
  </div>
</template>

<script>
	import {getProductDetails} from '@/api'
export default {
  data () {
    return {
		id:null,
		img:''
    };
  },

  components: {},

  created() {
	  this.id=this.$route.params.id
	  this.getProductDetails()
  },

  mounted() {},

  methods: {
	  async getProductDetails() {
		const res= await getProductDetails({id:this.id})  
		this.img=res.data.productWapCont
	  }
  }
}

</script>
<style lang='scss' scoped>
	img{
		display: block;
	}
</style>